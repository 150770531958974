export function bytesToSize(bytes) {
  if (bytes === 0) return '0 Byte';

  bytes = Number(bytes);

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function bytesToSizeM(bytes) {
  if (bytes === 0) return '0 Byte';

  bytes = Number(bytes);

  const sizes = ['Bytes', 'KB', 'MB'];
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  i = i > 2 ? 2 : i;

  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function bytesToBand(bytes) {
  if (bytes === 0) return 0;
  const bits = Number(bytes) * 8;

  return Math.round(bits / Math.pow(1024, 2), 2);
}

export function secondsToDhms(seconds) {
  seconds = Number(seconds);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor(seconds % (3600 * 24) / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function secondsToDhmsSimple(seconds) {
  seconds = Number(seconds);

  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor(seconds % (3600 * 24) / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + "d, " : "";
  const hDisplay = h > 0 ? h + "h, " : "";
  const mDisplay = m > 0 ? m + "m, " : "";
  const sDisplay = s + "s";

  return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function mtimeToTimeSimple(mtime) {
  return new Date(mtime);
}

export function vnameToTimeSimple(name) {
  name = name.replace('.mp4', '');

  let dateTimeArr = name.split('-');
  dateTimeArr = dateTimeArr.map(i => parseInt(i,10));
  dateTimeArr[1] = dateTimeArr[1] - 1;

  let dateTime = new Date(...dateTimeArr);

  if (process.env.NODE_ENV === 'production') {
    const offset = (new Date()).getTimezoneOffset() * -1;

    dateTime = new Date(dateTime.getTime() + offset * 60000);
  }

  return dateTime;
}
