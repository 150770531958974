import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../actions/session';

import { Button, Form, Input, Card, Layout, Alert } from 'antd';
import {
  DeploymentUnitOutlined,
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';
import './Login.css';

export default function Login() {
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.session);

  const onFinish = ({ username, password }) => {
    dispatch(loginUser({ username, password }));
  };

  function ErrorBlock() {
    return (
      <div className="login-error">
        {error && <Alert message={error} type="error" showIcon closable />}
      </div>
    )
  }

  return (
    <Layout className="login-wrapper" style={{ minHeight: "100vh" }}>
      <Card className="login-card">
        <div className="login-head">
          <DeploymentUnitOutlined />
        </div>

        <ErrorBlock />

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <Button className="login-button" type="primary" htmlType="submit">
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  );
}
