import React, { useEffect } from 'react';

import './StreamControls.css';
import { Slider, Form, Radio, Switch } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { updateStream } from "../../actions/stream";
import { useDispatch } from "react-redux";
import Options from "../Options/Options";

function StreamControls({ form, configs, streamName }) {
  const dispatch = useDispatch();
  const fpsValue = Form.useWatch('fps', form);
  const zoomValue = Form.useWatch('zoom', form);
  const bitrateQuality = Form.useWatch('bitrate', form);
  const bitrateValue = Form.useWatch('bitrate_value', form);
  const autoFocusValue = Form.useWatch('focus', form);
  const focusValue = Form.useWatch('focus_value', form);
  const whiteValue = Form.useWatch('white', form);
  const expoValue = Form.useWatch('expo', form);
  const cameraValue = Form.useWatch('camera', form);
  const fpsMarks = {
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
    110: '110',
    120: '120'
  };

  const bitrateMarks = {
    1200: 'Min',
    5200: 'Low',
    7200: 'Medium',
    9200: 'High',
    12000: 'Ultra',
    15000: 'Max'
  };

  useEffect(() => {
    form.resetFields();
  }, [form, configs]);

  function onValuesChange(values) {
    dispatch(updateStream(streamName, values));
  }

  const formatter = (value) => `${value} kbps`;

  return (
    <Form
      form={form}
      disabled={configs.isDefault}
      initialValues={configs}
      onValuesChange={onValuesChange}
      className='stream-controls'
    >
      <div className='settings-box'>
        <Options size='small' />
      </div>

      <div className='settings-box'>
        <button className="v-settings-button">
          Camera
        </button>

        <div className="settings-nav vjs-menu v-camera-opts">
          <div className='settings-nav-in'>
            <Form.Item
              label={`Camera ${cameraValue}`}
              name='camera'
            >
              <Radio.Group size="small">
                <Radio value='back'>back</Radio>
                <Radio value='front'>front</Radio>
                <Radio value='usb'>usb</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={`${zoomValue} zoom`}
              name='zoom'
            >
              <Slider
                step={1}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className='settings-box'>
        <button className="v-settings-button">
          FPS
        </button>

        <div className="settings-nav vjs-menu v-fps-opts">
          <div className='settings-nav-in'>
            <Form.Item
              label={`${fpsValue} fps`}
              name='fps'
            >
              <Slider
                marks={fpsMarks}
                step={10}
                min={30}
                max={120}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className='settings-box'>
        <button className="v-settings-button">
          Bitrate
        </button>

        <div className="settings-nav vjs-menu v-bitrate-opts">
          <div className='settings-nav-in'>
            <Form.Item label="Quality" name='bitrate'>
              <Radio.Group size="small">
                <Radio value='low'>Low</Radio>
                <Radio value='medium'>Medium</Radio>
                <Radio value='high'>High</Radio>
                <Radio value='ultra'>Ultra</Radio>
                <Radio value='custom'>Custom</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="bitrate_value"
              label={`${bitrateValue} kbps`}
            >
              <Slider
                disabled={bitrateQuality !== 'custom'}
                marks={bitrateMarks}
                step={100}
                min={1200}
                max={15000}
                tooltip={{ formatter }}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className='settings-box'>
        <button className="v-settings-button">
          Focus
        </button>

        <div className="settings-nav vjs-menu v-focus-opts">
          <div className='settings-nav-in'>
            <Form.Item label="Auto Focus" name='focus' valuePropName='checked'>
              <Switch
                checkedChildren={<EyeOutlined />}
                unCheckedChildren={<EyeInvisibleOutlined />}
              />
            </Form.Item>

            <Form.Item
              name="focus_value"
              label={`Distance ${focusValue}%`}
            >
              <Slider
                disabled={autoFocusValue === true}
                step={1}
                min={0}
                max={100}
              />
            </Form.Item>
          </div>
        </div>
      </div>

      <div className='settings-box'>
        <button className="v-settings-button">
          Brightness
        </button>

        <div className="settings-nav vjs-menu v-filter-opts">
          <div className='settings-nav-in'>
            <Form.Item
              name="white"
              label={`ISO ${whiteValue}`}
            >
              <Slider
                step={0.025}
                min={-1}
                max={1}
              />
            </Form.Item>

            <Form.Item
              name="expo"
              label={`Expo ${expoValue}`}
            >
              <Slider
                step={0.025}
                min={-1}
                max={1}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default StreamControls;
