import { createSlice } from '@reduxjs/toolkit';

export const defaultConfigs = {
  camera: 'back',
  fps: 30,
  white: 0,
  expo: 0,
  focus: true,
  focus_value: 50,
  bitrate: 'medium',
  bitrate_value: 7200,
  zoom: 1,
}

const streamSlice = createSlice({
  name: 'stream',
  initialState: {
    recording: false,
    videos: [],
    configs: {
      isDefault: true,
      ...defaultConfigs,
    }
  },
  reducers: {
    setStream(state, action) {
      const { isRecording, videos, configs } = action.payload;

      state.recording = isRecording;
      state.videos = videos;
      state.configs = configs;
    },
    setRecording(state, action) {
      state.recording = action.payload;
    },
    removeVideo(state, action) {
      state.videos = state.videos.filter(video => video.name !== action.payload);
    },
    resetConfigs(state) {
      state.configs = defaultConfigs;
    }
  },
});

export const { setStream, removeVideo, setRecording, resetConfigs } = streamSlice.actions;
export default streamSlice.reducer;
