import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { destroyVideo } from '../../actions/stream';

import VideoPreview from './VideoPreview';
import {
  bytesToSize,
  mtimeToTimeSimple,
  vnameToTimeSimple,
  secondsToDhmsSimple
} from '../../helpers/Util';

import { List, Card, Avatar, Button, Modal } from 'antd';
import {
  PlayCircleOutlined,
  DownloadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import './VideoList.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

function VideoList() {
  const dispatch = useDispatch();
  const [previewVideo, setPreviewVideo] = useState(null);
  const { streamName } = useSelector(state => state.session);
  const { videos } = useSelector(state => state.stream);

  function startAt({ name }) {
    return vnameToTimeSimple(name);
  }

  function endAt({ mtime }) {
    return mtimeToTimeSimple(mtime);
  }

  function size({ size }) {
    return bytesToSize(size);
  }

  function duration(video) {
    const seconds = (endAt(video) - startAt(video)) / 1000;
    return secondsToDhmsSimple(seconds);
  }

  function downloadLink(name) {
    return `${BASE_URL}/videos/${streamName}/${name}/download`
  }

  const onPreviewCancel = () => {
    setPreviewVideo(null);
  };

  const onPlayClick = (video) => {
    setPreviewVideo(video);
  };

  const onDestroyVideoClick = (name) => {
    dispatch(destroyVideo(streamName, name)).then(() => {
      if(previewVideo && previewVideo.name.indexOf(name) !== -1) {
        onPreviewCancel();
      }
    });
  };

  const confirmDestroy = (name) => {
    Modal.confirm({
      title: 'Are you sure delete this video?',
      icon: <ExclamationCircleOutlined />,
      content: name,
      onOk: () => onDestroyVideoClick(name),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No'
    });
  };

  function actions(video) {
    return [
      <Button
        key='play'
        className='play-btn'
        icon={<PlayCircleOutlined />}
        type='link'
        onClick={() => onPlayClick(video)}
      />,

      <Button
        key='download'
        className='download-btn'
        icon={<DownloadOutlined />}
        type='link'
        href={downloadLink(video.name)}
      />,

      <Button
        key='delete'
        className='delete-btn'
        icon={<DeleteOutlined />}
        type='link'
        onClick={() => confirmDestroy(video.name)}
        danger
      />
    ]
  }

  return (
    <>
      <List
        className='video-list'
        pagination={{
          pageSize: 16
        }}
        dataSource={videos || []}
        renderItem={video => (
          <List.Item>
            <Card actions={actions(video)}>
              <div className='body'>
                <Avatar
                  src={`${BASE_URL}/${video.sprite}`}
                  icon={<PlayCircleOutlined />}
                />

                <div className='time'>
                  <strong>Start:</strong> {startAt(video).toLocaleString('en-GB')} <br />
                  <strong>End:</strong> {endAt(video).toLocaleString('en-GB')}
                </div>
              </div>

              <div className='bottom'>
                <span>{duration(video)}</span>
                <span>{size(video)}</span>
              </div>
            </Card>
          </List.Item>
        )}
      />

      { previewVideo &&
        <VideoPreview
          videoUrl={`${BASE_URL}/videos/${streamName}/${previewVideo.name}`}
          sprite={`${BASE_URL}/${previewVideo.sprite}`}
          onCancel={onPreviewCancel}
        />
      }
    </>
  );
}

export default VideoList;
