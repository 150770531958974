import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetConfigs } from '../../actions/stream';

import { Button, Tooltip } from 'antd';

function ResetButton({ size, type }) {
  const dispatch = useDispatch();
  const { streamName } = useSelector(state => state.session);

  function onClickRestart() {
    dispatch(resetConfigs(streamName));
  }

  return (
    <Tooltip
      overlayClassName='reset-btn-tooltip'
      title="Reset configs to defaults"
      color='rgba(43, 51, 63, 0.7)'
    >
      <Button
        className='reset-btn'
        size={size}
        onClick={onClickRestart} type={type || 'dashed'}
      >
        Reset
      </Button>
    </Tooltip>
  );
}

export default ResetButton;
