import React, { useEffect, useRef } from 'react';
import { ReactComponent as PlayerControlsSvg } from './PlayerControls.svg';
import './VideoControls.css';
import StreamControls from "../StreamControls/StreamControls";
import {useSelector} from "react-redux";

import { VerticalLeftOutlined, VerticalRightOutlined } from "@ant-design/icons";

function VideoControls({ form, videoRef, children }) {
  const { streamName } = useSelector(state => state.session);
  const { configs } = useSelector(state => state.stream);

  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');
  let loopTimeout = null;
  let onMouseDownOffsets = null;
  let percentage = 100;
  let frameRate = 30;

  const controlsContainerRef = useRef(null);
  const videoControlsRef = useRef(null);
  const playButtonRef = useRef(null);
  const playIcoRef = useRef(null);
  const pauseIcoRef = useRef(null);
  const seekRef = useRef(null);
  const seekTooltipRef = useRef(null);
  const progressBarRef = useRef(null);
  const timeElapsedRef = useRef(null);
  const durationRef = useRef(null);
  const volumeButtonRef = useRef(null);
  const volumeMuteRef = useRef(null);
  const volumeLowRef = useRef(null);
  const volumeHighRef = useRef(null);
  const volumeRef = useRef(null);
  const fullscreenButtonRef = useRef(null);
  const videoContainerRef = useRef(null);
  const pipButtonRef = useRef(null);
  const zoomSliderRef = useRef(null);
  const zoomContentRef = useRef(null);
  const settingsButtonRef = useRef(null);
  const playbackRateRef = useRef(null);
  const videoMirrorRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    playButtonRef.current.addEventListener('click', togglePlay);
    videoRef.current.addEventListener('play', updatePlayButton);
    videoRef.current.addEventListener('pause', updatePlayButton);
    videoRef.current.addEventListener('loadedmetadata', initializeVideo);
    videoRef.current.addEventListener('timeupdate', onTimeupdate);

    videoRef.current.addEventListener('volumechange', updateVolumeIcon);

    seekRef.current.addEventListener('mousemove', updateSeekTooltip);
    seekRef.current.addEventListener('input', skipAhead);

    seekRef.current.addEventListener('keyup', (e) => e.preventDefault());
    seekRef.current.addEventListener('keydown', (e) => e.preventDefault());

    volumeRef.current.addEventListener('input', updateVolume);

    volumeButtonRef.current.addEventListener('click', toggleMute);
    fullscreenButtonRef.current.addEventListener('click', toggleFullScreen);
    controlsContainerRef.current.addEventListener('fullscreenchange', updateFullscreenButton);
    videoContainerRef.current.addEventListener('wheel', onWheel);
    pipButtonRef.current.addEventListener('click', togglePip);

    videoMirrorRef.current.addEventListener('click', toggleVideoMirror);

    document.addEventListener('keydown', keyboardShortcuts);

    if (!('pictureInPictureEnabled' in document)) {
      pipButtonRef.current.classList.add('hidden');
    }

    seekRef.current.value = 0;
    volumeRef.current.value = 0;
  }, []); // eslint-disable-line

  function togglePlay() {
    const video = videoRef.current;
    const isPlaying = video.currentTime > 0 && !video.paused && !video.ended
        && video.readyState > video.HAVE_CURRENT_DATA;

    if (!isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }

  function updatePlayButton() {
    if (videoRef.current.paused) {
      playIcoRef.current.classList.remove('hidden');
      pauseIcoRef.current.classList.add('hidden');
    } else {
      playIcoRef.current.classList.add('hidden');
      pauseIcoRef.current.classList.remove('hidden');
    }
  }

  function formatTime(timeInSeconds) {
    const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

    return {
      minutes: result.substr(3, 2),
      seconds: result.substr(6, 2),
    };
  }

  function getDuration() {
    const duration = Math.trunc(videoRef.current.duration);

    if (duration !== Infinity) {
      return duration;
    } else if(videoRef.current.buffered.length > 0) {
      return Math.trunc(videoRef.current.buffered.end(0));
    }
  }

  function initializeVideo() {
    updateDuration();
  }

  function updateDuration() {
    const videoDuration = getDuration();

    if (!videoDuration) {
      return;
    }

    const time = formatTime(videoDuration);
    seekRef.current.setAttribute('max', videoDuration);
    progressBarRef.current.setAttribute('max', videoDuration);

    durationRef.current.innerText = `${time.minutes}:${time.seconds}`;
    durationRef.current.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
  }

  function updateTimeElapsed() {
    const time = formatTime(Math.trunc(videoRef.current.currentTime));
    timeElapsedRef.current.innerText = `${time.minutes}:${time.seconds}`;
    timeElapsedRef.current.setAttribute('datetime', `${time.minutes}m ${time.seconds}s`);
  }

  function updateProgress() {

    const currentTime = videoRef.current.currentTime;
    seekRef.current.value = currentTime;
    progressBarRef.current.value = currentTime;

    updateDuration();
  }

  function onTimeupdate() {
    updateTimeElapsed();
    updateProgress();
  }

  function skipAhead(event) {
    let skipTo = event.target.dataset.seek
        ? event.target.dataset.seek
        : event.target.value;

    seekTo(skipTo);
  }

  function seekTo(skipTo) {
    const duration = getDuration();

    if (!duration || Number.isNaN(duration)) {
      skipTo = 0;
    } else {
      skipTo = skipTo > duration ? duration : skipTo;
    }

    seekRef.current.value = skipTo;
    progressBarRef.current.value = skipTo;
    videoRef.current.currentTime = skipTo;
  }

  function updateSeekTooltip(event) {
    const max = event.target.getAttribute('max');

    if (!max) {
      return;
    }

    const skipTo = Math.round(
        (event.offsetX / event.target.clientWidth) *
        parseInt(max, 10)
    );
    seekRef.current.setAttribute('data-seek', skipTo);
    const t = formatTime(skipTo);
    seekTooltipRef.current.textContent = `${t.minutes}:${t.seconds}`;
    const rect = videoRef.current.getBoundingClientRect();
    seekTooltipRef.current.style.left = `${event.pageX - rect.left}px`;
  }

  function updateVolume() {
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
    }

    videoRef.current.volume = volumeRef.current.value;
  }

  function updateVolumeIcon() {
    volumeButtonRef.current.querySelectorAll('use').forEach((icon) => {
      icon.classList.add('hidden');
    });

    if (videoRef.current.muted || videoRef.current.volume === 0) {
      volumeMuteRef.current.classList.remove('hidden');
    } else if (videoRef.current.volume > 0 && videoRef.current.volume <= 0.5) {
      volumeLowRef.current.classList.remove('hidden');
    } else {
      volumeHighRef.current.classList.remove('hidden');
    }
  }

  function toggleMute() {
    videoRef.current.muted = !videoRef.current.muted;

    if (videoRef.current.muted) {
      volumeRef.current.setAttribute('data-volume', volumeRef.current.value);
      volumeRef.current.value = 0;
    } else {
      volumeRef.current.value = volumeRef.current.dataset.volume;
    }
  }

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else if (document.webkitFullscreenElement) {
      document.webkitExitFullscreen();
    } else if (controlsContainerRef.current.webkitRequestFullscreen) {
      controlsContainerRef.current.webkitRequestFullscreen();
    } else {
      controlsContainerRef.current.requestFullscreen();
    }
  }

  function updateFullscreenButton() {
    fullscreenButtonRef.current.querySelectorAll('use')
        .forEach((icon) => icon.classList.toggle('hidden'));
  }

  async function togglePip() {
    try {
      if (videoRef.current !== document.pictureInPictureElement) {
        pipButtonRef.current.disabled = true;
        await videoRef.current.requestPictureInPicture();
      } else {
        await document.exitPictureInPicture();
      }
    } catch (error) {
      console.error(error);
    } finally {
      pipButtonRef.current.disabled = false;
    }
  }

  // Zoom
  function onSliderMove(sliderPercentage) {
    if (sliderPercentage <= 100) {
      dispose();
    } else {
      percentage = sliderPercentage;
      videoRef.current.style.setProperty('pointer-events', 'none');
      updateZoom();
      preview();

      videoContainerRef.current.addEventListener('mousedown', onMousedown)
      videoContainerRef.current.addEventListener('mouseup', onMouseup);
    }
  }

  function dispose() {
    videoContainerRef.current.removeEventListener('mousedown', onMousedown);
    videoContainerRef.current.removeEventListener('mousemove', onMousemove);

    if (canvas.parentNode === videoContainerRef.current) {
      videoContainerRef.current.removeChild(canvas);
    }

    if (loopTimeout) {
      clearTimeout(loopTimeout);
      loopTimeout = null;
    }

    const mirrorScale = isMirror() ? ' scale(-1, 1)' : '';
    videoContainerRef.current.style.overflow = 'visible';
    videoRef.current.style.removeProperty('pointer-events');
    videoRef.current.style.transform = `scale(1)${mirrorScale}`;
    videoRef.current.style.removeProperty('top');
    videoRef.current.style.removeProperty('left');
  }

  function preview() {
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.position = 'absolute';

    configurePreviewScale();

    if (loopTimeout == null) {
      loopTimeout = setTimeout(previewLoop, frameRate);
      videoContainerRef.current.appendChild(canvas);
    }
  }

  function isMirror() {
    return videoRef.current.classList.contains('vc-video-mirror');
  }

  function updateZoom() {
    const scale = percentage / 100;
    const mirrorScale = isMirror() ? ' scale(-1, 1)' : '';
    videoContainerRef.current.style.overflow = 'hidden';
    videoRef.current.style.transform = `scale(${scale})${mirrorScale}`;
  }

  function previewLoop() {
    if (!videoRef.current || !videoRef.current.parentElement) {
      return;
    }

    const mirrorScale = isMirror() ? ' scale(-1, 1)' : '';
    const ratioX = canvas.width / videoRef.current.videoWidth;
    const ratioY = canvas.height / videoRef.current.videoHeight;
    const ratio = Math.min(ratioX, ratioY);

    ctx.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth * ratio, videoRef.current.videoHeight * ratio);

    const normalizedPercentage = percentage / 100;
    const recWidth = ((videoRef.current.videoWidth * ratio) / normalizedPercentage);
    const recHeight = ((videoRef.current.videoHeight * ratio) / normalizedPercentage);

    const video = videoRef.current.getBoundingClientRect();
    const parent = videoRef.current.parentElement.getBoundingClientRect();

    const offsetRatioW = (videoRef.current.videoWidth / 2) / video.width;
    const offsetRatioH = (videoRef.current.videoHeight / 2) / video.height;

    const top = ((-(video.top - parent.top) * offsetRatioH) * ratio) * 2;
    const left = ((-(video.left - parent.left) * offsetRatioW) * ratio) * 2;

    ctx.beginPath();
    ctx.strokeStyle = 'white';
    ctx.rect(left, top, recWidth, recHeight);
    ctx.stroke();
    canvas.style.transform = mirrorScale;

    loopTimeout = setTimeout(previewLoop, frameRate);
  }

  function configurePreviewScale() {
    canvas.width = parseInt(getComputedStyle(videoRef.current).width) / 4;
    canvas.height = parseInt(getComputedStyle(videoRef.current).height) / 4;
  }

  function onMousedown(e) {
    onMouseDownOffsets = [videoRef.current.offsetLeft - e.clientX, videoRef.current.offsetTop - e.clientY];
    videoContainerRef.current.addEventListener('mousemove', onMousemove);
  }

  function onMouseup() {
    videoContainerRef.current.removeEventListener('mousemove', onMousemove);
  }

  function onMousemove(e) {
    let top = e.clientX + onMouseDownOffsets[0];
    let left = e.clientY + onMouseDownOffsets[1];
    const normalizePercentage = percentage / 100;
    const maxOffsetWidth = videoRef.current.offsetWidth / 2 * (normalizePercentage - 1);
    const maxOffsetHeight = videoRef.current.offsetHeight / 2 * (normalizePercentage - 1);

    top = top > maxOffsetWidth ? maxOffsetWidth : top;
    left = left > maxOffsetHeight ? maxOffsetHeight : left;

    if (top < (-1 * maxOffsetWidth)) {
      top = -1 * maxOffsetWidth;
    }

    if (left < (-1 * maxOffsetHeight)) {
      left = -1 * maxOffsetHeight;
    }

    videoRef.current.style.left = `${top}px`;
    videoRef.current.style.top = `${left}px`;
  }

  function onSliderChange() {
    onSliderMove(zoomSliderRef.current.value);
    onRangeUpdate(zoomSliderRef.current.value);
  }

  function onClickResetZoom() {
    zoomSliderRef.current.value = 100;
    onSliderMove(100);
    onRangeUpdate(100);
  }

  function onRangeUpdate(value) {
    zoomContentRef.current.innerHTML = `${value}%`;
  }

  function onWheel(e) {
    e.preventDefault();

    let newValue;
    const value = parseInt(zoomSliderRef.current.value, 10);

    if (e.deltaY > 0) {
      newValue = value - 5;
      zoomSliderRef.current.value = newValue > 500 ? 500 : newValue;
    } else {
      newValue = value + 5;
      zoomSliderRef.current.value = newValue < 100 ? 100 : newValue;
    }

    onSliderChange();
  }

  function seekVal() {
    let fps = form.getFieldValue('fps');

    if (isNaN(fps)) {
      fps = 30;
    }

    return parseFloat((1 / fps).toFixed(3));
  }

  function seekBack(val = 1) {
    const now     = videoRef.current.currentTime;
    const skipTo  = Math.max(0, now - seekVal() * val);

    seekTo(skipTo);
  }

  function seekForward(val = 1) {
    const now = videoRef.current.currentTime;
    const duration = getDuration();
    const skipTo = Math.min(now + seekVal() * val, duration);

    seekTo(skipTo);
  }

  function seekEnd() {
    const duration = getDuration();
    const skipTo = duration - 0.1;

    seekTo(skipTo);
  }

  function keyboardShortcuts(event) {
    const { code } = event;

    switch (code) {
      case 'Space':
        event.preventDefault();
        togglePlay();
        break;
      case 'KeyF':
        event.preventDefault();
        toggleFullScreen();
        break;
      case 'KeyM':
        event.preventDefault();
        toggleMute();
        break;
      case 'KeyP':
        event.preventDefault();
        togglePip();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        seekBack(1);
        break;
      case 'ArrowRight':
        event.preventDefault();
        seekForward(1);
        break;
      case 'ArrowUp':
        event.preventDefault();
        seekForward(5);
        break;
      case 'ArrowDown':
        event.preventDefault();
        seekBack(5);
        break;
      case 'Enter':
        event.preventDefault();
        seekEnd();
        break;
      default:
        break;
    }
  }

  function onClickPlaybackRate(e, rate) {
    controlsContainerRef.current.querySelectorAll('.playback-button').forEach(e => e.parentElement.classList.remove('active'));

    e.target.parentElement.classList.add('active');

    playbackRateRef.current.innerHTML = `${rate}x`;
    videoRef.current.playbackRate = rate;
  }

  function toggleVideoMirror(e) {
    e.target.classList.toggle('vc-video-mirror');
    videoRef.current.classList.toggle('vc-video-mirror');

    updateZoom();
  }

  return(
      <div className='vc-container' ref={controlsContainerRef}>
        <div className='vc-video-container' ref={videoContainerRef}>
          {children}
        </div>

        <div className="video-controls" ref={videoControlsRef}>
          <div className="video-progress">
            <progress value="0" ref={progressBarRef}></progress>
            <input className="seek input-range" min="0" type="range" step="0.01" ref={seekRef} />
            <div className="seek-tooltip" ref={seekTooltipRef}>00:00</div>
          </div>

          <div className="bottom-controls">
            <div className="left-controls">
              <button className='vc-btn' ref={playButtonRef}>
                <svg className="vc-svg playback-icons">
                  <use className="play-ico" href="#play-icon" ref={playIcoRef}></use>
                  <use className="pause-ico hidden" href="#pause" ref={pauseIcoRef}></use>
                </svg>
              </button>

              <div className="volume-controls">
                <button className="vc-btn volume-button" ref={volumeButtonRef}>
                  <svg className='vc-svg'>
                    <use ref={volumeMuteRef} href="#volume-mute"></use>
                    <use className="hidden" ref={volumeLowRef} href="#volume-low"></use>
                    <use className="hidden" ref={volumeHighRef} href="#volume-high"></use>
                  </svg>
                </button>

                <input className="volume input-range"
                       data-mute="0.5" type="range" max="1" min="0" step="0.01" ref={volumeRef} />
              </div>

              <div className="time">
                <time ref={timeElapsedRef}>00:00</time>
                <span> / </span>
                <time ref={durationRef}>00:00</time>
              </div>
            </div>

            <div className="right-controls">
              <StreamControls
                  form={form}
                  configs={configs}
                  streamName={streamName}
              />

              <div className='video-mirror'>
                <button className="vc-btn" ref={videoMirrorRef}>
                  <VerticalLeftOutlined className='vc-mirror-icon-left' />
                  <VerticalRightOutlined className='vc-mirror-icon-right' />
                </button>
              </div>

              <div className='settings-box'>
                <button className="vc-btn settings-button" ref={playbackRateRef}>
                  1x
                </button>

                <div className="settings-nav vjs-menu playback-nav">
                  <ul className='vjs-menu-content'>
                    <li>
                      <button className='vc-btn playback-button' onClick={(e) => onClickPlaybackRate(e, 2)}>2x</button>
                    </li>
                    <li>
                      <button className='vc-btn playback-button' onClick={(e) => onClickPlaybackRate(e, 1.5)}>1.5x</button>
                    </li>
                    <li className='active'>
                      <button className='vc-btn playback-button' onClick={(e) => onClickPlaybackRate(e, 1)}>1x</button>
                    </li>
                    <li>
                      <button className='vc-btn playback-button' onClick={(e) => onClickPlaybackRate(e, 0.5)}>0.5x</button>
                    </li>
                    <li>
                      <button className='vc-btn playback-button' onClick={(e) => onClickPlaybackRate(e, 0.25)}>0.25x</button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='settings-box'>
                <button className="vc-btn settings-button" ref={settingsButtonRef}>
                  Zoom
                </button>

                <div className="settings-nav vjs-menu">
                  <ul className='vjs-menu-content'>
                    <li>
                      <button className='vc-btn zoom-reset-button' onClick={onClickResetZoom}>Reset</button>
                    </li>
                    <li>
                      <input
                          ref={zoomSliderRef}
                          type='range'
                          min='100'
                          max='500'
                          step='1'
                          className='zoom-slider'
                          onChange={onSliderChange}
                      />
                    </li>
                    <li>
                      <span ref={zoomContentRef}>100%</span>
                    </li>
                  </ul>
                </div>
              </div>

              <button className="vc-btn pip-button" ref={pipButtonRef}>
                <svg className='vc-svg'>
                  <use href="#pip"></use>
                </svg>
              </button>

              <button className="vc-btn fullscreen-button" ref={fullscreenButtonRef}>
                <svg className='vc-svg'>
                  <use href="#fullscreen"></use>
                  <use href="#fullscreen-exit" className="hidden"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <PlayerControlsSvg />
      </div>
  );
}

export default VideoControls;
