import React, { useState, useEffect } from 'react';
import { fetchStats } from '../../actions/stats';
import { bytesToSize, bytesToSizeM } from '../../helpers/Util';

import { Progress } from 'antd';
import './ServerStats.css';

function ServerStats() {
  const [cpu, setCpu] = useState(0);
  const [ram, setRam] = useState(0);
  const [disk, setDisk] = useState(0);
  const [cpuTitle, setCpuTitle] = useState(0);
  const [ramTitle, setRamTitle] = useState(bytesToSizeM(0));
  const [diskTitle, setDiskTitle] = useState(bytesToSize(0));

  useEffect(() => {
    fetch();
    const timer = setInterval(fetch, 2000);

    return () => {
      clearInterval(timer);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function calcLoad(free, total) {
    return (100 - 100 * free / total).toFixed(0);
  }

  function fetch() {
    fetchStats().then(({ cpu, mem, disk }) => {
      setCpu(cpu.load);
      setRam(calcLoad(mem.free, mem.totle));
      setDisk(calcLoad(disk.free, disk.total));

      setCpuTitle(cpu.num);
      setRamTitle(bytesToSizeM(mem.totle - mem.free));
      setDiskTitle(bytesToSize(disk.used));
    });
  }

  function percentFormat(percent) {
    return `${percent}%`;
  }

  return (
    <>
      <div className='stats-box'>
        <div className='stats-title'>{cpuTitle} CPUs on Linux</div>
        <Progress
          percent={cpu}
          format={percentFormat}
          size="small"
        />
      </div>
      <div className='stats-box'>
        <div className='stats-title'>{ramTitle} RAM</div>
        <Progress
          percent={ram}
          format={percentFormat}
          size="small"
        />
      </div>
      <div className='stats-box'>
        <div className='stats-title'>{diskTitle}</div>
        <Progress
          percent={disk}
          format={percentFormat}
          size="small"
        />
      </div>
    </>
  );
}

export default ServerStats;
