import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Player from '../Player/Player';
import VideoList from '../Videos/VideoList';

import { Col, Row } from 'antd';
import { fetchStream } from '../../actions/stream';
import './Streams.css';

function StreamIndex() {
  const dispatch = useDispatch();
  const videoRef = createRef();
  const { streamName } = useSelector(state => state.session);

  useEffect(() => {
    dispatch(fetchStream(streamName));
  }, [dispatch, streamName]);

  return (
    <div className="stream-index">
      <Row wrap={false}>
        <Col flex="800px">
          <Player videoRef={videoRef} />
        </Col>

        <Col flex="auto">
          <VideoList />
        </Col>
      </Row>
    </div>
  );
}

export default StreamIndex;
