import React, { useEffect, useRef, useState } from 'react';

import VideoJS from './VideoJS';
import Timeline from './Timeline';
import BrightnessSlider from './BrightnessSlider';

import { Modal } from 'antd';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import './VideoPreview.css';

const ANIMATION_CLASS = 'timeline-animation';

const VideoPreview = ({ videoUrl, sprite, onCancel }) => {
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const scrollRef = useRef(null);
  const draggleRef = useRef(null);
  const contentRef = useRef(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [height, setHeight] = useState(750);
  const [width, setWidth] = useState(800);

  let seeking;
  let seekWasPlaying;

  useEffect(() => {
    const player = playerRef.current;

    if (player) {
      playerRef.current.src({ src: videoUrl, type: 'video/mp4' });
      player.currentTime(0);
    }
  }, [videoUrl]);

  function onDrag(_event, uiData) {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  }

  function onResize(event, { size }) {
    setHeight(size.height);
    setWidth(size.width);
  }

  function onPlayerReady(player, video) {
    playerRef.current = player;
    videoRef.current = video;
    player.on('timeupdate', onTimeUpdate);
    player.on('fullscreenchange', () => scrollRef.current.updateMetrics());
    player.focus();
  }

  function onTimeUpdate() {
    if (seeking || !scrollRef.current) {
      return;
    }

    seekTimeLine();
  }

  function onLineUpdate(state) {
    if (!seeking || !playerRef.current || !playerRef.current.player_) {
      return;
    }

    if (state.isMoving) {
      seekPlayer(state.position.x);
    } else {
      seeking = false;
      contentRef.current.classList.add(ANIMATION_CLASS);

      if(seekWasPlaying) {
        playerRef.current.play();
      }
    }
  }

  function onPointerDown() {
    seeking = true;
    seekWasPlaying = !playerRef.current.paused();
    playerRef.current.pause();
    contentRef.current.classList.remove(ANIMATION_CLASS);
  }

  function seekTimeLine() {
    const duration = playerRef.current.duration();
    const currentTime = playerRef.current.currentTime();
    const spriteWidth = contentRef.current.width;

    let pos = currentTime / duration * 100;

    if(isNaN(pos)) {
      pos = 0;
    }

    const position = spriteWidth * pos / 100;
    scrollRef.current.setPosition({ x: position, y: 0 });
  }

  function seekPlayer(position) {
    const duration = playerRef.current.duration();
    const currentTime = playerRef.current.currentTime();
    const spriteWidth = contentRef.current.width;

    let pos = position / spriteWidth * 100;

    if(isNaN(pos)) {
      pos = 0;
    }

    const sec = Math.round(duration * pos / 100 * 10) / 10;

    if (currentTime !== sec) {
      playerRef.current.currentTime(sec);
    }
  }

  const renderModal = (modal) => {
    return (
      <Draggable
        bounds={bounds}
        onStart={onDrag}
        handle=".header-video-preview"
      >
        <div ref={draggleRef}>{modal}</div>
      </Draggable>
    );
  };

  return (
    <>
      <Modal
        centered={true}
        destroyOnClose={true}
        className='video-preview'
        wrapClassName='video-preview-wrap'
        visible={!!videoUrl}
        footer={null}
        mask={false}
        maskClosable={false}
        width={width}
        onCancel={onCancel}
        modalRender={renderModal}
      >
        <div className='header-video-preview' />
        <ResizableBox
          width={width}
          height={height}
          minConstraints={[200, 200]}
          onResize={onResize}
        >
          <div data-vjs-player>
            <VideoJS
              options={{
                autoplay: true,
                controls: true,
                responsive: true,
                fluid: true,
                timeDivider: true,
                playbackRates: [0.25, 0.5, 1, 1.5, 2],
                sources: [{
                  src: videoUrl,
                  type: 'video/mp4'
                }]
              }}
              onReady={onPlayerReady}
            />

            <div className='video-settings no-draggable'>
              <Timeline
                scrollRef={scrollRef}
                contentRef={contentRef}
                sprite={sprite}
                onUpdate={onLineUpdate}
                onPointerDown={onPointerDown}
              />

              <BrightnessSlider videoRef={videoRef} />
            </div>
          </div>
        </ResizableBox>
      </Modal>
    </>
  );
};

export default VideoPreview;
