import React, {useEffect} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { launchApp } from '../../actions/session';

import Login from '../Login/Login';
import Dashboard from '../dashboard/Dashboard';
import StreamIndex from '../streams/StreamIndex';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';

import { Layout, Spin } from 'antd';
import './App.css';

const { Content } = Layout;

function App() {
  const dispatch = useDispatch();
  const { token, loading } = useSelector(state => state.session);

  useEffect(() => {
    if (token) {
      dispatch(launchApp());
    }
  }, [dispatch, token]);

  if(!token) {
    return <Login />;
  }

  if (loading) {
    return (
      <div className='loading-page'>
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />

        <Layout>
          <Header />

          <Content style={{ margin: '24px 16px', minHeight: 280 }}>
            <Route exact path="/" component={StreamIndex} />
            <Route path="/dashboard" component={Dashboard} />
          </Content>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
