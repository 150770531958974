import request from '../apis/request';
import { setStream, removeVideo, setRecording, resetConfigs as resetConfigsReducer, defaultConfigs } from '../store/streamReducer';

export const fetchStream = (streamName) => async dispatch => {
  request(`/streams/${streamName}`).then((response) => {
    dispatch(setStream(response));
  });
};

export const updateStream = (id, params) => async dispatch => {
  request(`/streams/${id}`, params, 'PATCH');
};

export const restartStream = (id) => async dispatch  => {
  return request(`/streams/${id}/restart`, {}, 'POST');
};

export const startRecord = (id) => async dispatch  => {
  return request(`/streams/${id}/record`, {}, 'POST').then(() => {
    dispatch(setRecording(true));
  });
};

export const stopRecord = (id) => async dispatch => {
  return request(`/streams/${id}/stop_record`, {}, 'POST').then(() => {
    dispatch(setRecording(false));
  });
};

export const destroyVideo = (streamName, name) => async dispatch => {
  request(`/videos/${streamName}/${name}`, {}, 'DELETE').then(() => {
    dispatch(removeVideo(name));
  });
};

export const resetConfigs = (id) => async dispatch => {
  request(`/streams/${id}`, defaultConfigs, 'PATCH').then(() => {
    dispatch(resetConfigsReducer());
  });
};
