import React from 'react';

import { Slider } from 'antd';

function BrightnessSlider({ videoRef }) {
  function onChangeBrightness(value) {
    if (videoRef.current) {
      videoRef.current.style.filter = `brightness(${value[0]})`;
    }
  }

  return (
    <Slider
      range
      step={0.025}
      min={0}
      max={3}
      defaultValue={1}
      onChange={onChangeBrightness}
    />
  );
}

export default BrightnessSlider;
