import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStream, startRecord, stopRecord } from '../../actions/stream';

import { Badge, Button } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import './Options.css';
import RestartButton from "../ResetButton/RestartButton";
import ResetButton from "../ResetButton/ResetButton";

function Options({ size }) {
  const dispatch = useDispatch();
  const { streamName } = useSelector(state => state.session);
  const { recording } = useSelector(state => state.stream);

  const onToggleRecordClick = () => {
    if(recording) {
      dispatch(stopRecord(streamName)).then(() => dispatch(fetchStream(streamName)));
    } else {
      dispatch(startRecord(streamName)).then(() => {});
    }
  };

  const RecordingIcon = () => {
    if (recording) {
      return (
        <Badge className='v-rec-badge' count={<ClockCircleOutlined style={{ color: '#f5222d' }} />}>
          <Button className='v-rec-btn' size={size} onClick={onToggleRecordClick} type="dashed">
            <Badge status="processing" text="Stop Recording.." />
          </Button>
        </Badge>
      );
    } else {
      return (
        <Button className='v-rec-btn' size={size} onClick={onToggleRecordClick} type="dashed">
          <Badge status="error" text="Start Recording" />
        </Button>
      );
    }
  };

  return (
    <div className="video-options">
      <RestartButton size={size} />
      <RecordingIcon />
      <ResetButton size={size} />
    </div>
  );
}

export default Options;
