import React, { useRef, useEffect } from 'react';

import ScrollBooster from 'scrollbooster';
import './Timeline.css';

function Timeline(props) {
  const viewportRef = useRef(null);
  const {
    scrollRef,
    contentRef,
    sprite,
    onUpdate,
    onPointerDown,
  } = props;

  useEffect(() => {
    scrollRef.current = new ScrollBooster({
      viewport: viewportRef.current,
      content: contentRef.current,
      direction: 'horizontal',
      scrollMode: 'transform',
      bounce: false,
      bounceForce: 0,
      onUpdate: onUpdate,
      onPointerDown: onPointerDown
    });

    return () => {
      if (scrollRef.current) {
        scrollRef.current.destroy();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={viewportRef}
      className='timeline'
    >
      <img
        className='timeline-animation'
        alt='timeline'
        ref={contentRef}
        src={sprite}
      />
    </div>
  );
}

export default Timeline;
