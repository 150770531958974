import React, { useRef, useEffect } from 'react';
import videojs from './video.js';

import 'video.js/dist/video-js.css';
import './VideoJS.css';

export const VideoJS = ({ options, onReady }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(
        videoElement,
        {
          ...options,
          userActions: {
            hotkeys: function (event) {
              switch (event.which) {
                case 13:
                  event.preventDefault();
                  seekEnd(this);
                  break;
                case 37:
                  event.preventDefault();
                  seekBack(this, 1);
                  break;
                case 39:
                  event.preventDefault();
                  seekForward(this, 1);
                  break;
                case 38:
                  event.preventDefault();
                  seekForward(this, 5);
                  break;
                case 40:
                  event.preventDefault();
                  seekBack(this, 5);
                  break;
                case 32:
                  event.preventDefault();
                  this.paused() ? this.play() : this.pause();
                  break;
                case 88:
                  event.preventDefault();
                  this.pause();
                  break;
                case 89:
                  event.preventDefault();
                  this.play();
                  break;
                default:
                  break;
              }
            }
          },
          plugins: {
            zoom: {
              framerate: 30
            }
          }
        },
        () => onReady && onReady(player, videoRef.current)
      );
    }
  }, [options, videoRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  function seekBack(player, value = 1) {
    const now = player.currentTime();

    player.currentTime(Math.max(0, now - (0.1 * value)));
  }

  function seekForward(player, value = 1) {
    const now = player.currentTime();
    let duration = player.duration();

    if (player.liveTracker && player.liveTracker.isLive()) {
      duration = player.liveTracker.seekableEnd();
    }

    player.currentTime(Math.min(now + (0.1 * value), duration));
  }

  function seekEnd(player) {
    let duration = player.duration();

    if (player.liveTracker && player.liveTracker.isLive()) {
      duration = player.liveTracker.seekableEnd();
    }

    player.currentTime(duration);
  }

  return (
    <video
      preload='auto'
      ref={videoRef}
      className='video-js vjs-big-play-centered'
    />
  );
};

export default VideoJS;
