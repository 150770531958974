import React, { useState, useEffect } from 'react';

import './Clock.css';

const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function Clock() {
  const newDate = new Date();
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [fullDate, setFullDate] = useState('');

  useEffect(() => {
    updateAll();

    const timer = setInterval(updateAll, 1000);
    newDate.setDate(newDate.getDate());

    return () => {
      clearInterval(timer);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function updateDate() {
    setFullDate(
      `${dayNames[newDate.getDay()]} ${newDate.getDate()} ${monthNames[newDate.getMonth()]} ${newDate.getFullYear()}`
    );
  }

  function updateSeconds(theDate) {
    const seconds = theDate.getSeconds();
    setSeconds(( seconds < 10 ? '0' : '' ) + seconds);
  }

  function updateMinutes(theDate) {
    const minutes = theDate.getMinutes();
    setMinutes(( minutes < 10 ? '0' : '' ) + minutes);
  }

  function updateHours(theDate) {
    const hours = theDate.getHours();
    setHours(( hours < 10 ? '0' : '' ) + hours);
  }

  function updateAll() {
    const theDate = new Date();
    updateDate();

    updateSeconds(theDate);
    updateMinutes(theDate);
    updateHours(theDate);
  }

  return (
    <>
      <div className='clock-container'>
        <div className='time-date'>{fullDate}</div>

        <ul>
          <li className='time-hours'>{hours}</li>
          <li className='point'>:</li>
          <li className='time-min'>{minutes}</li>
          <li className='point'>:</li>
          <li className='time-sec'>{seconds}</li>
        </ul>
      </div>
    </>
  );
}

export default Clock;
