export const getToken = () => {
  const tokenString = localStorage.getItem('token');
  return JSON.parse(tokenString);
};

export const removeToken = () => {
  localStorage.removeItem('token');
};

export const saveToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token));
};
