import React from 'react';
import { createBrowserHistory } from 'history';
import { useSelector } from 'react-redux';

import {
  DeploymentUnitOutlined,
  // DashboardOutlined,
  // VideoCameraOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

import './Sidebar.css';

const { Sider } = Layout;

function Sidebar() {
  const { collapsed } = useSelector(state => state.session);

  const menuItems = [
    // {
    //   label:
    //     <Link to="/">
    //       <VideoCameraOutlined />
    //       <span>Streams</span>
    //     </Link>,
    //   key: '/'
    // },
    // {
    //   label:
    //     <Link to="/dashboard">
    //       <DashboardOutlined />
    //       <span>Dashboard</span>
    //     </Link>,
    //   key: '/dashboard'
    // }
  ];

  return (
    <Sider
      width={256}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className="logo">
        <Link to="/">
          <DeploymentUnitOutlined />
        </Link>
      </div>

      <Menu
        items={menuItems} theme="dark" mode="inline"
        defaultSelectedKeys={[createBrowserHistory().location.pathname]}
      />
    </Sider>
  );
}

export default Sidebar;
