import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginOut, toggleSidebar } from '../../actions/session';

import ServerStats from '../ServerStats/ServerStats';
import Clock from '../ServerStats/Clock';

import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Col, Row } from 'antd';
import './Header.css';

const HeaderBlock = Layout.Header;

function Header() {
  const dispatch = useDispatch();
  const { collapsed } = useSelector(state => state.session);

  const toggle = () => {
    dispatch(toggleSidebar(!collapsed));
  };

  const FoldIcon = () => {
    if (collapsed) {
      return <MenuUnfoldOutlined className="header-icons trigger" onClick={toggle} />
    } else {
      return <MenuFoldOutlined className="header-icons trigger" onClick={toggle} />
    }
  };

  const onLogOutClick = () => {
    dispatch(loginOut());
  };

  return (
    <HeaderBlock className='header' style={{ background: '#ffffff', padding: 0 }}>
      <Row wrap={false}>
        <Col className='left-box' flex="70px">
          <FoldIcon />
        </Col>

        <Col className='center-box' flex="auto">
          <ServerStats />
        </Col>

        <Col className='right-box' flex="250px">
          <Clock />
          <LogoutOutlined className="header-icons" onClick={onLogOutClick} />
        </Col>
      </Row>
    </HeaderBlock>
  );
}

export default Header;
