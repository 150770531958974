import videojs from "video.js";

const attachMirrorButton = (player, mirrorButton) => {
  const myButtonDom = mirrorButton.el();
  myButtonDom.classList.add('video-mirror-btn');
  myButtonDom.innerHTML = '' +
      '<span role="img" aria-label="vertical-left" class="anticon anticon-vertical-left vc-mirror-icon-left"><svg viewBox="64 64 896 896" focusable="false" data-icon="vertical-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M762 164h-64c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V172c0-4.4-3.6-8-8-8zm-508 0v72.4c0 9.5 4.2 18.4 11.4 24.5L564.6 512 265.4 763.1c-7.2 6.1-11.4 15-11.4 24.5V860c0 6.8 7.9 10.5 13.1 6.1L689 512 267.1 157.9A7.95 7.95 0 00254 164z"></path></svg></span>' +
      '<span role="img" aria-label="vertical-right" class="anticon anticon-vertical-right vc-mirror-icon-right"><svg viewBox="64 64 896 896" focusable="false" data-icon="vertical-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M326 164h-64c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V172c0-4.4-3.6-8-8-8zm444 72.4V164c0-6.8-7.9-10.5-13.1-6.1L335 512l421.9 354.1c5.2 4.4 13.1.7 13.1-6.1v-72.4c0-9.4-4.2-18.4-11.4-24.5L459.4 512l299.2-251.1c7.2-6.1 11.4-15.1 11.4-24.5z"></path></svg></span>';

  myButtonDom.onclick = function() {
    myButtonDom.classList.toggle('vc-video-mirror');
    player.children()[0].classList.toggle('vc-video-mirror');
    player.zoom.updateZoom();
  }
}

class ZoomMenuButton extends videojs.getComponent('MenuButton') {
  createMenu() {
    const menu = new ZoomMenu(this.player(), this.options);
    const el = this.el().getElementsByClassName('vjs-icon-placeholder')[0];
    const span = document.createElement('span');
    span.classList.add('vjs-icon-cog', 'zoom-menu-button-icon');
    el.appendChild(span);
    return menu;
  }
}

class Slider extends videojs.getComponent('MenuItem') {
  createEl() {
    const liEl = document.createElement('li');
    liEl.classList.add('zoom-slider-parent');

    this.rangeInput = document.createElement('input');
    this.rangeInput.type = 'range';
    this.rangeInput.min = '100';
    this.rangeInput.max = '500';
    this.rangeInput.step = '1';
    this.rangeInput.id = `range-slider-${this.player().id()}`;
    this.rangeInput.classList.add('zoom-slider');
    liEl.appendChild(this.rangeInput);
    return liEl;
  }
}

class ResetZoomButton extends videojs.getComponent('MenuItem') {
  createEl() {
    const div = document.createElement('li');
    this.resetButton = document.createElement('button');
    this.resetButton.innerHTML = 'Reset';
    this.resetButton.classList.add('zoom-reset-button');
    div.appendChild(this.resetButton);

    return div;
  }
}

class ZoomContent extends videojs.getComponent('MenuItem') {
  createEl() {
    const liEl = document.createElement('li');
    this.zoomContent = document.createElement('p');
    this.zoomContent.classList.add('zoom-label');
    liEl.appendChild(this.zoomContent);
    return liEl;
  }

  onRangeUpdate(value) {
    this.zoomContent.innerHTML = `${value}%`;
  }
}

class ZoomMenu extends videojs.getComponent('Menu') {
  constructor(player, opts) {
    super(player, opts);

    const reset = new ResetZoomButton(player, opts);
    this.addChild(reset, {}, 0);
    const slider = new Slider(player, opts);
    this.addChild(slider, {}, 1);
    const rangeContent = new ZoomContent(player, opts);

    player.el().addEventListener('wheel', (e) => {
      e.preventDefault();

      let newValue;
      const value = parseInt(slider.rangeInput.value, 10);

      if (e.deltaY > 0) {
        newValue = value - 5;
        slider.rangeInput.value = newValue > 500 ? 500 : newValue;
      } else {
        newValue = value + 5;
        slider.rangeInput.value = newValue < 100 ? 100 : newValue;
      }

      rangeContent.onRangeUpdate(newValue);
      player.zoom.onSliderMove(newValue);
    });

    rangeContent.onRangeUpdate(slider.rangeInput.value);
    slider.rangeInput.addEventListener('input', () => {
      rangeContent.onRangeUpdate(slider.rangeInput.value);
      player.zoom.onSliderMove(slider.rangeInput.value);
    });
    reset.resetButton.onclick = function () {
      slider.rangeInput.value = '100';
      rangeContent.onRangeUpdate(slider.rangeInput.value);
      player.zoom.onSliderMove(slider.rangeInput.value);
    }
    this.addChild(rangeContent, {}, 2);
    let menuContent = this.el().getElementsByClassName('vjs-menu-content')[0];
    menuContent.style.minHeight = '18em';
  }
}

let canvas = document.createElement('canvas');
let ctx = canvas.getContext('2d');
let loopTimeout = null;
let onMouseDownOffsets = null;
let percentage = 100;

function ZoomPlugin(options) {
  const player = this;
  let settings = videojs.mergeOptions({}, options);

  player.zoom = {
    playerVideo: player.children()[0],
    framerate: options.framerate === 0 ? 1000 / 30 : options.framerate,
    isMirror: function () {
      return player.children()[0].classList.contains('vc-video-mirror');
    },
    onSliderMove: function (sliderPercentage) {
      if (sliderPercentage <= 100) {
        this.dispose();
      } else {
        percentage = sliderPercentage;
        this.playerVideo.style.setProperty('pointer-events', 'none');
        this.updateZoom();
        this.preview();
        player.on('mousedown', (e) => {
          onMouseDownOffsets = [this.playerVideo.offsetLeft - e.clientX, this.playerVideo.offsetTop - e.clientY];
          player.on('mousemove', (ev) => this.onMouseMove(ev.clientX, ev.clientY));
        })
        player.on('mouseup', (e) => {
          player.off('mousemove');
        });
      }
    },
    dispose: function () {
      player.off('mousedown');
      player.off('mousemove');
      if (canvas.parentNode === player.el())
        player.el().removeChild(canvas);

      if (loopTimeout) {
        clearTimeout(loopTimeout);
        loopTimeout = null;
      }
      const mirrorScale = player.zoom.isMirror() ? ' scale(-1, 1)' : '';
      player.el().style.overflow = 'visible';
      this.playerVideo.style.removeProperty('pointer-events');
      this.playerVideo.style.transform = `scale(1)${mirrorScale}`;
      this.playerVideo.style.removeProperty('top');
      this.playerVideo.style.removeProperty('left');
    },
    preview: function () {
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.style.position = 'absolute';
      // canvas.style.border = '1px solid gray';
      this.configurePreviewScale();

      if (loopTimeout == null) {
        loopTimeout = setTimeout(() => this.previewLoop(), this.frameRate);
        player.el().appendChild(canvas);
      }
    },
    updateZoom: function () {
      player.el().style.overflow = 'hidden';
      let scale = percentage / 100;
      const mirrorScale = player.zoom.isMirror() ? ' scale(-1, 1)' : '';
      this.playerVideo.style.transform = `scale(${scale})${mirrorScale}`;
    },
    previewLoop: function () {
      if (!this.playerVideo || !this.playerVideo.parentElement) {
        return;
      }

      const mirrorScale = player.zoom.isMirror() ? ' scale(-1, 1)' : '';
      const ratioX = canvas.width / this.playerVideo.videoWidth;
      const ratioY = canvas.height / this.playerVideo.videoHeight;
      const ratio = Math.min(ratioX, ratioY);

      ctx.drawImage(this.playerVideo, 0, 0, this.playerVideo.videoWidth * ratio, this.playerVideo.videoHeight * ratio);
      const normalizedPercentage = percentage / 100;
      const recWidth = ((this.playerVideo.videoWidth * ratio) / normalizedPercentage);
      const recHeight = ((this.playerVideo.videoHeight * ratio) / normalizedPercentage);
      const video = this.playerVideo.getBoundingClientRect();
      const parent = this.playerVideo.parentElement.getBoundingClientRect();
      const offsetRatioW = (this.playerVideo.videoWidth / 2) / video.width;
      const offsetRatioH = (this.playerVideo.videoHeight / 2) / video.height;
      let top = ((-(video.top - parent.top) * offsetRatioH) * ratio) * 2;
      let left = ((-(video.left - parent.left) * offsetRatioW) * ratio) * 2;

      ctx.beginPath();
      ctx.strokeStyle = 'white';
      ctx.rect(left, top, recWidth, recHeight);
      ctx.stroke();
      canvas.style.transform = mirrorScale;

      loopTimeout = setTimeout(() => this.previewLoop(), this.frameRate);
    },
    configurePreviewScale: function () {
      canvas.width = parseInt(getComputedStyle(this.playerVideo).width) / 4;
      canvas.height = parseInt(getComputedStyle(this.playerVideo).height) / 4;
    },
    onMouseMove: function (clientX, clientY) {
      let top = clientX + onMouseDownOffsets[0];
      let left = clientY + onMouseDownOffsets[1];
      let normalizePercentage = percentage / 100;
      let maxOffsetWidth = this.playerVideo.offsetWidth / 2 * (normalizePercentage - 1);
      let maxOffsetHeight = this.playerVideo.offsetHeight / 2 * (normalizePercentage - 1);

      if (top > maxOffsetWidth) {
        top = maxOffsetWidth;
      }

      if (top < (-1 * maxOffsetWidth))
        top = -1 * maxOffsetWidth;

      if (left > maxOffsetHeight) {
        left = maxOffsetHeight;
      }

      if (left < (-1 * maxOffsetHeight)) {
        left = -1 * maxOffsetHeight;
      }

      this.playerVideo.style.left = `${top}px`;
      this.playerVideo.style.top = `${left}px`;
    }
  }
  player.on('fullscreenchange', () => player.zoom.configurePreviewScale());
  player.on('dispose', () => player.zoom.onSliderMove(0));
  player.on('loadedmetadata', () => {
    let menuButton = new ZoomMenuButton(player, settings);
    const mirrorButton = player.controlBar.addChild('button', {}, player.controlBar.children().length - 2);
    player.controlBar.addChild(menuButton, {}, player.controlBar.children().length - 2);

    attachMirrorButton(player, mirrorButton);
  });
}

videojs.registerPlugin('zoom', ZoomPlugin);

export default videojs;
