import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { restartStream } from '../../actions/stream';

import { Button } from 'antd';

import './RestartButton.css';

function RestartButton({ size, type }) {
  const dispatch = useDispatch();
  const [loadings, setLoadings] = useState([]);
  const { streamName } = useSelector(state => state.session);

  function onClickRestart() {
    enterLoading(0);
    dispatch(restartStream(streamName));
  }

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        window.location.reload();
        return newLoadings;
      });
    }, 3000);
  };

  return (
    <Button
      className='restart-btn'
      size={size}
      loading={loadings[0]}
      onClick={onClickRestart} type={type || 'dashed'}
    >
      Restart
    </Button>
  );
}

export default RestartButton;
