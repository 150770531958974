import React, { useEffect } from 'react';
import flv from 'flv.js';
import { useSelector } from 'react-redux';
import VideoControls from "./VideoControls";

import './Player.css';

import { Form } from "antd";
import StreamControls from "../StreamControls/StreamControls";

const API_URL = process.env.REACT_APP_API_URL;

function Player({ videoRef }) {
  const { appName, streamName, sign } = useSelector(state => state.session);
  const { configs } = useSelector(state => state.stream);
  const [form] = Form.useForm();
  let player;

  useEffect(() => {
    if (!player) {
      buildPlayer();
    }

    return () => {
      if (player) {
        player.destroy();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buildPlayer = () => {
    if (player) {
      return;
    }

    player = flv.createPlayer(
      {
        type: 'flv',
        url: `${API_URL}/${appName}/${streamName}.flv?sign=${sign}`
      },
      {
        isLive: true,
        stashInitialSize: 128,
        enableStashBuffer: false
      }
    );

    player.attachMediaElement(videoRef.current);
    player.on(flv.Events.ERROR, (errorType, errorDetail) => {
      console.log( "FLV Error event", errorType, errorDetail );
    });

    player.load();
    player.play().catch(() => {});
  };

  return (
    <>
      <VideoControls form={form} videoRef={videoRef}>
        <video
          ref={videoRef}
          className='video-player'
          muted='muted'
        />
      </VideoControls>

      <div className="main-options">
        <div className='video-options'>
          <StreamControls form={form} configs={configs} streamName={streamName} />
        </div>
      </div>
    </>
  );
}

export default Player;
