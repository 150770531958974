import { getToken } from '../helpers/Token';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// public routes that don't require authentication
const PUBLIC_ROUTES = [
  `${BASE_URL}/login`
];

export default (path, params= {}, method = 'GET') => {
  let url = `${BASE_URL}${path}`;
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  if(!PUBLIC_ROUTES.includes(url)) {
    options.headers.Authorization = `Bearer ${getToken()}`;
  }

  if( 'GET' === method ) {
    if (Object.keys(params).length) {
      url += '?' + ( new URLSearchParams( params ) ).toString();
    }
  } else {
    options.body = JSON.stringify( params );
  }

  return fetch( url, options ).then( response => response.json() );
};

